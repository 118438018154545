// BAvatar custom styles

.b-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: inherit;
  font-weight: 400;
  line-height: 1;
  max-width: 100%;
  max-height: auto;
  text-align: center;
  overflow: visible;
  position: relative;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:focus {
    outline: 0;
  }

  &.btn,
  &[href] {
    padding: 0;
    border: 0;

    .b-avatar-img img {
      transition: transform 0.15s ease-in-out;
    }

    &:not(:disabled):not(.disabled) {
      cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

      &:hover {
        .b-avatar-img img {
          transform: scale(1.15);
        }
      }
    }
  }

  &.disabled,
  &:disabled,
  &[disabled] {
    opacity: $btn-disabled-opacity;
    pointer-events: none;
  }

  .b-avatar-custom,
  .b-avatar-text,
  .b-avatar-img {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .b-avatar-text {
    text-transform: uppercase;
    white-space: nowrap;
  }

  &[href] {
    text-decoration: none;
  }

  > .b-icon {
    width: 60%;
    height: auto;
    max-width: 100%;
  }

  .b-avatar-img img {
    width: 100%;
    height: 100%;
    max-height: auto;
    border-radius: inherit;
  }

  .b-avatar-badge {
    // Positioning will be handled via inline styles
    position: absolute;
    min-height: 1.5em;
    min-width: 1.5em;
    padding: 0.25em;
    line-height: 1;
    border-radius: 10em;
    font-size: 70%;
    font-weight: 700;
    z-index: 5;
  }
}

.b-avatar-group {
  .b-avatar-group-inner {
    display: flex;
    flex-wrap: wrap;
  }

  .b-avatar {
    border: $border-width solid $border-color;
  }

  a,
  .btn {
    &.b-avatar:hover:not(.disabled):not(disabled) {
      z-index: 3;
    }
  }
}
